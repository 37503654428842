import { createRouter, createWebHistory } from 'vue-router'
import authCheck from '../services/auth-check';
import authiSAdmin from '../services/auth-isAdmin';
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '../views/Menu.vue')
  },
  {
    path: '/acesso_negado',
    name: 'AcessoNegado',
    component: () => import(/* webpackChunkName: "acesso_negado" */ '../views/AcessoNegado.vue')
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = 'Criscomp Sistema - Bar & Restaurante ' + to.name;
  if(to.name === 'Home'){
    next();
  }else if(to.name === 'Admin'){
    if(authiSAdmin()){
      next();
    }else{
      next({ name: 'Menu' });
    }
  }else if(authCheck()){
    next();
  }else{
    if(to.name !== 'Login' && to.name !== 'Home'){
      next({ name: 'Login' });
    }else{
      next();
    }
  }
});

export default router
