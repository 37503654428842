import authUrl from './auth-url';
import authHeader from './auth-header';
import axios from "axios";
import router from '../router';

class AuthService {
    login(user) {
        return axios
        .post(authUrl() + 'login', {
            cpf: user.cpf,
            password: user.password
        }, authHeader())
        .then(response => {
            if (response.data.access_token) {
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    check() {
        axios
        .post(authUrl() + 'check', authHeader())
        .then(response => {
            console.log(response)
            // if (response.data.) {
            //     localStorage.removeItem('user');
            //     localStorage.setItem('user', JSON.stringify(response.data));
            // }
            // return response.data;
        }).catch(error => {
            console.log('chamou error')
            console.error(error)
        });
    }

    logout() {
        // axios
        // .post(authUrl() + 'logout', {}, authHeader(JSON.parse(localStorage.getItem('user'))))
        // .then(() => {
        localStorage.removeItem('user');
        router.push('/login');
        // });
    }
}

export default new AuthService();