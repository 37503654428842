import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { storeAuth } from './store/auth'
import axios from 'axios';
import VueAxios from 'vue-axios';
// https://github.com/devindex/vue-mask
import VueMask from '@devindex/vue-mask';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
window.Pusher = Pusher;
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '915c9366848768c868db',
    cluster: 'us2'
});

import 'bootstrap/dist/css/bootstrap.css';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import store from './store'

const app = createApp(App)
app.use(store);
app.use(router);
app.use(storeAuth);
app.use(VueMask)
app.use(VueAxios, axios);
app.use(VueSweetalert2)
app.mount('#app');
