export default function authHeader(user = null) {
  let _user = null;
  if(user){
    _user = user;
  }else{
    _user = JSON.parse(localStorage.getItem('user'));
  }

  let _headers = {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      // "Access-Control-Allow-Origin": "*"
  }

  if (_user && _user.access_token) {
    _headers.Authorization = 'Bearer ' + _user.access_token;
    // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  }

  return _headers;
}