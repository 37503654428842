<template>
  <div style="background: #e7ab35;">
    <router-view/>
  </div>
</template>
<style scoped>
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll; 
}

body::-webkit-scrollbar {
  display: none;
}
</style>